import axios from "axios"



export const create = (item, token) => {
  return axios.post(process.env.VUE_APP_API_URL + `handouts/`, {
    media_id: item.media_id,
    description: item.description,
    name: item.name,
    category_id: item.category_id,
    tags: item.tags,
    date: item.date,
    visible: item.visible || false,
    sort_order: item.sort_order,
   
  }, {
      headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const update = (id, item, token) => {
  return axios.put(process.env.VUE_APP_API_URL + `handouts/` + id, {
    media_id: item.media_id,
    description: item.description,
    name: item.name,
    category_id: item.category_id,
    tags: item.tags,
    date: item.date,
    visible: item.visible || false,
    sort_order: item.sort_order,
  
  }, {
      headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const getById = (id, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `handouts/` + id, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const getAll = (search, page, count, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `handouts?search=${search}&count=${count}&page=${page}&withCategory=true`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const deleteR = (id, token) => {
  return axios.delete(process.env.VUE_APP_API_URL + `handouts/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}