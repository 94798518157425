<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb :crumbs='generateBreadCrumb()'></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-overlay :show="isBusy">
        <b-card>
          <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <b-form
              class="needs-validation"
              @submit.prevent="handleSubmit(itemFormSubmit)"
              novalidate
            >
              <h6 class="heading-small text-muted mb-4">Content</h6>

              <div class="">
                <b-row>
                  <b-col lg="12">
                    <media-input
                      format="document"
                      name="PDF File"
                      label="PDF File"
                      :required="true"
                      v-model="itemForm.media_id"
                    >
                    </media-input>
                  </b-col>
                  <b-col lg="6">
                    <base-input
                      type="text"
                      :name="'Name - English'"
                      label="Name - English"
                      :required="true"
                      placeholder="Name - English"
                      v-model="itemForm.name.en"
                    >
                    </base-input>
                  </b-col>
                  <b-col lg="6">
                    <base-input
                      type="text"
                      :name="'Name - Arabic'"
                      label="Name - Arabic"
                      :required="true"
                      placeholder="Name - Arabic"
                      v-model="itemForm.name.ar"
                    >
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6">
                    
                    <html-editor
                      :name="'Description - English'"
                      label="Description - English"
                      :rows="'5'"
                      :required="true"
                       placeholder="Description - English"
                      :type="'textarea'"
                       v-model="itemForm.description.en"
                    >
                   
                    </html-editor>
                  </b-col>

                  <b-col lg="6">
                     <html-editor
                      :name="'Description - Arabic'"
                      label="Description - Arabic"
                      :rows="'5'"
                      :required="true"
                       placeholder="Description - Arabic"
                      :type="'textarea'"
                       v-model="itemForm.description.ar"
                    ></html-editor>
                  </b-col>
                 

                  <b-col lg="6">
                     <base-input
                      type="select"
                      :required="true"
                      label="Category"
                      :name="'Type'"
                      placeholder="Target Type"
                      v-model="itemForm.category_id"
                      :options="categories"
                      :key-field="'_id'" 
                      :value-field="'_id'"
                      :text-field="'name'"
                    ></base-input>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group>
                      <label class="form-control-label">Tags</label>
                      <b-form-tags
                        input-id="tags-basic"
                        v-model="itemForm.tags"
                      ></b-form-tags>
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group>
                     
                      <base-input :required="true" :label="'Date'" :name="'Date'" type="date" v-model="itemForm.date"/>

                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                     <base-input
                      :name="'Sort Order'"
                      label="Sort Order"
                      :required="true"
                       placeholder="Sort Order"
                      type="number"
                       v-model="itemForm.sort_order"
                    >
                   
                    </base-input>
                  </b-col>

                 
                 
                  <b-col lg="6" >
                     <b-form-group>
                       <label class="form-control-label">Visible</label>
                    <b-form-checkbox class="mb-3" v-model="itemForm.visible">
                      Hide from mobile app
                    </b-form-checkbox>
                     </b-form-group>
                  </b-col>
                </b-row>
              </div>

              <base-button type="primary" native-type="submit" class="my-4">
                {{ isUpdate() ? "Update Information" : "Create" }}
              </base-button>
            </b-form>
          </validation-observer>

         
         
        </b-card>
      </b-overlay>
    </b-container>
  </div>
</template>
<script>
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import moment from "moment";
import MediaInput from "@/components/Inputs/MediaInput";
import * as categoriesRepo from "@/api-repo/categories";
import * as handoutsRepo from "@/api-repo/handouts";

import { handleError, successMessage } from "@/util/error-handler";
export default {
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
    MediaInput,
    HtmlEditor
  },
  data() {
    return {
      isBusy: false,
      categories: [],
      types: [
        {name: 'Recipe', value: 'recipe'},
        {name: 'Handout', value: 'handout'},
       
      ],
      resorts: [
        {name: 'None', value: null},
        {name: 'Serenity', value: 'serenity'},
        {name: 'Discovery', value: 'discovery'},
       
      ],
      itemForm: {
        media_id: null,
        tags: [],
        date: moment().format('yyyy-MM-DD'),
        name: { en: null, ar: null },
        description: { en: null, ar: null },
        category_id: null,
        visible: null,
        sort_order: null,
      },
    };
  },
  created() {
    this.getInitData();
    if (this.isUpdate()) {
      this.getData();
    }
  },
  /*

media_id: 
    title: {en: , ar: }
    text: {en: , ar: }
    target_id: 
    target_type: 
    hidden: 
    tags: []
  */
  methods: {
generateBreadCrumb() {
        return [{label: "Handouts", route: "Handouts"},{label: this.isUpdate() ? this.itemForm.name.en : "Add New Handout"}];
      },
    getInitData: async function () {
      let res = await categoriesRepo.get('handout','',1,100,this.userStore.state.token);
      this.categories = res.data.data[0].rows;
      this.categories = this.categories.map((a) => {
        a.name = a.name.en
        return a;
      })

      console.log(this.categories);
    },
    getData: async function () {
      try {
        this.isBusy = true;
        let result = await handoutsRepo.getById(
          this.$route.params.id,
          this.userStore.state.token
        );
        this.itemForm.media_id = result.data.data.media_id;
        this.itemForm.name = result.data.data.name;
        this.itemForm.description = result.data.data.description;
        this.itemForm.visible = result.data.data.visible  || false;
        this.itemForm.date = result.data.data.date && moment(result.data.data.date).format('yyyy-MM-DD') || moment().format('yyyy-MM-DD');
        this.itemForm.category_id = result.data.data.category_id;
        this.itemForm.sort_order = result.data.data.sort_order;
       this.itemForm.sort_order = result.data.data.sort_order ? parseInt(result.data.data.sort_order): null;
        this.itemForm.tags = result.data.data.tags;
      //  this.itemForm.target_type = result.data.data.target_type;

      } catch (e) {
        handleError(e);
      } finally {
        this.isBusy = false;
      }
    },
    isUpdate: function () {
      return this.$route.params.id != null;
    },
    itemFormSubmit: async function () {
      const isValid = await this.$refs.formValidator.validate();
      if (isValid) {
       this.itemForm.sort_order = this.itemForm.sort_order ? parseInt(this.itemForm.sort_order) : null;
        //this.itemForm.visible = this.itemForm.visible;
        if (!this.isUpdate()) {
          try {
            this.isBusy = true;
            let result = await handoutsRepo.create(
              this.itemForm,
              this.userStore.state.token
            );
            successMessage("Item created successfully");
            this.$router.replace({ path: "/content/handouts" });
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        } else {
          try {
            this.isBusy = true;
            let result = await handoutsRepo.update(
              this.$route.params.id,
              this.itemForm,
              this.userStore.state.token
            );
            successMessage("Item updated successfully");
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        }
      }
    },

  },
};
</script>
